import {Component, OnDestroy, OnInit} from '@angular/core';
import {VideoService} from "../service/video.service";
import { ActivatedRoute } from "@angular/router";
import {Observable, Subscription} from "rxjs";

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss']
})
export class VideosComponent implements OnInit, OnDestroy {
  videos$: Observable<any>;
  subs: Subscription = new Subscription();

  constructor(private route: ActivatedRoute, private videoService: VideoService) {}

  ngOnInit() {
    console.log('Video');
    this.subs.add(this.route.paramMap.subscribe(params => {
      this.videos$ = this.videoService.getVideosByTag(params.get('tag'));
    }))
  }

  ngOnDestroy() {
    this.subs.unsubscribe()
  }
}
