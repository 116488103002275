import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {
  @Input()
  info: any

  safeSrc: any;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
    if (this.info) {
      this.safeSrc =  this.sanitizer.bypassSecurityTrustResourceUrl(this.info.url);
    }
  }

}
