import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
  @Output()
  toggleMenu: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {

  }

  toggleSideNav() {
    this.toggleMenu.emit(true)
  }
}
