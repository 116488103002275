import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {VIDEOS} from "./videos";
import {BehaviorSubject, Observable, of, Subject, Subscriber, Subscription} from "rxjs";
import {map} from "rxjs/operators";
import {VideoInfo} from "./video.model";

@Injectable({
  providedIn: 'root'
})
export class VideoService {
  // By default use local videos file and BehaviorSubject has "current" version of videos for a new subscriber.
  // NgRX store... not yet.
  videos$: Subject<VideoInfo[]> = new BehaviorSubject<VideoInfo[]>(VIDEOS);

  constructor(protected http: HttpClient) {
    this.refresh();
  }

  public refresh(): void {
    if( environment.isVideoRemote ) {
      this.http.get<VideoInfo[]>(environment.VIDEO_URL).subscribe(vids => this.videos$.next(vids));
    }
  }

  public getVideosByTag(tag: string): Observable<VideoInfo[]> {
    return this.videos$.pipe(
       map( (vids: VideoInfo[]) => {
         return vids.filter(v => v.tags.includes(tag))
       })
    );
  }
}
