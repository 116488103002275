<mat-sidenav-container>
  <mat-sidenav>
    <mat-nav-list>
      <a mat-list-item routerLink="2021">2021</a>
      <a mat-list-item routerLink="2020">2020</a>
      <a mat-list-item routerLink="2019">2019</a>
      <a mat-list-item routerLink="2018">2018</a>
      <a mat-list-item routerLink="about">About</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <div>
      <ng-content></ng-content>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
