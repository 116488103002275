<mat-toolbar color="primary">
  <mat-toolbar-row>
    <button mat-icon-button>
      <mat-icon (click)="toggleSideNav()">menu</mat-icon>
    </button>
    <h1  routerLink="/">C-Frederick</h1>
    <span class="menu-spacer"></span>
    <div class="toolbar-links">
      <a mat-button routerLink="2021">2021</a>
      <a mat-button routerLink="2020">2020</a>
      <a mat-button routerLink="2019">2019</a>
    </div>
    <div class="badges">
      <a href="https://www.instagram.com/cef142"><img src="assets/images/instagram-brands.svg" alt="instagram"></a>
      <a href="https://twitter.com/cef142"><img src="assets/images/twitter-brands.svg" alt="twitter"></a>
      <a href="https://www.strava.com/athletes/909431"><img src="assets/images/strava-brands.svg" alt="strava"></a>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
