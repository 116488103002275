import {VideoInfo} from "./video.model";

export const VIDEOS: VideoInfo[] = [
  {
    title: `Souderton Storm - State Cheer Competition Spring 2022`,
    url: 'https://www.youtube.com/embed/_9yi2AxchiI',
    videoLoc: 'YOUTUBE',
    videoId: '_9yi2AxchiI',
    tags: ['2022', 'home']
  },
  {
    title: `Legoland`,
    url: 'https://www.youtube.com/embed/_O9DWnlbrEI',
    videoLoc: 'YOUTUBE',
    videoId: '_O9DWnlbrEI',
    tags: ['2022', 'home']
  },
  {
    title: `Ella's Urban Air birthday party!`,
    url: 'https://www.youtube.com/embed/DbvE0RuLajU',
    videoLoc: 'YOUTUBE',
    videoId: 'DbvE0RuLajU',
    tags: ['2022', 'home']
  },
  {
    title: `New Years Eve 2021`,
    url: 'https://www.youtube.com/embed/6Q3MkLtRKeQ',
    videoLoc: 'YOUTUBE',
    videoId: '6Q3MkLtRKeQ',
    tags: ['2021', 'home']
  },
  {
    title: `Christmas 2021`,
    url: 'https://www.youtube.com/embed/nwQqSu6G62c',
    videoLoc: 'YOUTUBE',
    videoId: 'nwQqSu6G62c',
    tags: ['2021', 'home']
  },
  {
    title: `Christmas in Philadelphia 2021`,
    url: 'https://www.youtube.com/embed/-1sXbK54qmk',
    videoLoc: 'YOUTUBE',
    videoId: '-1sXbK54qmk',
    tags: ['2021', 'home']
  },
  {
    title: `First day of school 2021`,
    url: 'https://www.youtube.com/embed/k6bLYwj40YY',
    videoLoc: 'YOUTUBE',
    videoId: 'k6bLYwj40YY',
    tags: ['2021']
  },
  {
    title: `Massachusetts 2021`,
    url: 'https://www.youtube.com/embed/bD6yI9tnOVM',
    videoLoc: 'YOUTUBE',
    videoId: 'bD6yI9tnOVM',
    tags: ['2021', 'home']
  },
  {
    title: `July 4th splashdown 2021`,
    url: 'https://www.youtube.com/embed/10TP4qlOKeI',
    videoLoc: 'YOUTUBE',
    videoId: '10TP4qlOKeI',
    tags: ['2021', 'home']
  },
  {
    title: `Christmas 2020`,
    url: 'https://www.youtube.com/embed/n51uQgB9kQ0',
    videoLoc: 'YOUTUBE',
    videoId: 'n51uQgB9kQ0',
    tags: ['2020']
  },
  {
    title: `Pumpkin Picking 2020`,
    url: 'https://www.youtube.com/embed/qf0nT59CUoA',
    videoLoc: 'YOUTUBE',
    videoId: 'qf0nT59CUoA',
    tags: ['2020']
  },
  {
    title: `Lenox 2020`,
    url: 'https://www.youtube.com/embed/pAH2dt3N-VY',
    videoLoc: 'YOUTUBE',
    videoId: 'pAH2dt3N-VY',
    tags: ['2020']
  },
  {
    title: `Ella Biking`,
    url: 'https://www.youtube.com/embed/EXMmrhYbGLU',
    videoLoc: 'YOUTUBE',
    videoId: 'EXMmrhYbGLU',
    tags: ['2020']
  },
  {
    title: `Easter 2020`,
    url: 'https://www.youtube.com/embed/WFucPlBSn9I',
    videoLoc: 'YOUTUBE',
    videoId: 'WFucPlBSn9I',
    tags: ['2020']
  },
  {
    title: `Ella's 7th Birthday`,
    url: 'https://www.youtube.com/embed/I_qvBNSrOqQ',
    videoLoc: 'YOUTUBE',
    videoId: 'I_qvBNSrOqQ',
    tags: ['2020']
  },
  {
    title: `Nora's 5th Birthday Party`,
    url: 'https://www.youtube.com/embed/pHj6NhtBhwM',
    videoLoc: 'YOUTUBE',
    videoId: 'pHj6NhtBhwM',
    tags: ['2020']
  },
  {
    title: 'Christmas',
    url: 'https://www.youtube.com/embed/fbKURaRnuqw',
    videoLoc: 'YOUTUBE',
    videoId: 'fbKURaRnuqw',
    tags: ['2019']
  },
  {
    title: 'Halloween',
    url: 'https://www.youtube.com/embed/HCB3X0NUBwE',
    videoLoc: 'YOUTUBE',
    videoId: 'HCB3X0NUBwE',
    tags: ['2019']
  },
  {
    title: 'Beach',
    url: 'https://www.youtube.com/embed/tXB9i6PQML8',
    videoLoc: 'YOUTUBE',
    videoId: 'tXB9i6PQML8',
    tags: ['2019']
  },
  {
    title: `Megan and Tom's Picnic`,
    url: 'https://www.youtube.com/embed/suPWFvBoSQ8',
    videoLoc: 'YOUTUBE',
    videoId: 'suPWFvBoSQ8',
    tags: ['2019']
  },
  {
    title: `Palmer Park Picnic`,
    url: 'https://www.youtube.com/embed/cHUY_Qzrv9M',
    videoLoc: 'YOUTUBE',
    videoId: 'cHUY_Qzrv9M',
    tags: ['2019']
  },
  {
    title: `July 4th`,
    url: 'https://www.youtube.com/embed/qvHHlmLXTco',
    videoLoc: 'YOUTUBE',
    videoId: 'qvHHlmLXTco',
    tags: ['2019']
  },
  {
    title: `IVMS Ride July 10th`,
    url: 'https://www.youtube.com/embed/Nnv7x9olnCo',
    videoLoc: 'YOUTUBE',
    videoId: 'Nnv7x9olnCo',
    tags: ['2019']
  },
  {
    title: 'Great Wolf Lodge',
    url: 'https://www.youtube.com/embed/6NMZTTr4Ppk',
    videoLoc: 'YOUTUBE',
    videoId: '6NMZTTr4Ppk',
    tags: ['2019']
  },
  {
    title: 'Nora Birthday',
    url: 'https://www.youtube.com/embed/Yswexek_Qig',
    videoLoc: 'YOUTUBE',
    videoId: 'Yswexek_Qig',
    tags: ['2018']
  },
  {
    title: 'Christmas 2018',
    url: 'https://www.youtube.com/embed/bx0qpYW5VVc',
    videoLoc: 'YOUTUBE',
    videoId: 'bx0qpYW5VVc',
    tags: ['2018']
  },
  {
    title: 'Philadelphia Christmas 2018',
    url: 'https://www.youtube.com/embed/Ng2IDEenLtg',
    videoLoc: 'YOUTUBE',
    videoId: 'Ng2IDEenLtg',
    tags: ['2018']
  },
  {
    title: `Gymnastics`,
    url: 'https://www.youtube.com/embed/iFCoCUn4fsM',
    videoLoc: 'YOUTUBE',
    videoId: 'iFCoCUn4fsM',
    tags: ['2018']
  },
  {
    title: 'Beach',
    url: 'https://www.youtube.com/embed/57mch_5cnTY',
    videoLoc: 'YOUTUBE',
    videoId: '57mch_5cnTY',
    tags: ['2018']
  },
];
