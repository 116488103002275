import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {VideosComponent} from "./videos/videos.component";
import {AboutComponent} from "./about/about.component";

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full'},
  { path: 'about', component: AboutComponent },
  { path: ':tag', component: VideosComponent },
  { path: 'videos/:tag', component: VideosComponent },
  { path: '2022', redirectTo: '/videos/2022', pathMatch: 'full' },
  { path: '2021', redirectTo: '/videos/2021', pathMatch: 'full' },
  { path: '2020', redirectTo: '/videos/2020', pathMatch: 'full' },
  { path: '2019', redirectTo: '/videos/2019', pathMatch: 'full' },
  { path: '2018', redirectTo: '/videos/2018', pathMatch: 'full' },
  { path: '*', redirectTo: '/home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
