<mat-card class="example-card" *ngIf="info">
  <mat-card-header>
    <mat-card-title>{{info.title}}</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <div class="video-container">
      <iframe
        width="853" height="480"
        [src]="safeSrc"
        frameborder="0"
        allow="autoplay; encrypted-media" allowfullscreen>
      </iframe>
    </div>
  </mat-card-content>
</mat-card>
